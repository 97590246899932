.table_container {
  background-color: #5ab3f7;
  height: 400px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
}

table {
  color: white !important;
}

td,
th {
  border: none !important;
}
.modalTitle {
  color: whitesmoke;
  font-size: 1.75em;
}

.modal-content {
  border-radius: 1em !important;
  border: white solid 1px !important;
}

.modal-header {
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
  background-color: #0070b9;
  border-bottom: none;
}

.modal-body {
  background-color: #0070b9;
}

.modal-footer {
  border-bottom-left-radius: 1em !important;
  border-bottom-right-radius: 1em !important;
  background-color: #0070b9;
  border-top: none;
}

.modal-title {
  font-size: 1.5rem;
}

input[type]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.custom-input {
  border-radius: 0px;
  border: none;
  background-color: rgb(147, 147, 147, 0.58);
  color: white;
  height: 50px;
}

.custom-select {
  border-radius: 0px;
  border: none;
  background-color: rgb(147, 147, 147, 0.58);
  color: white;
  height: 50px;
  -webkit-appearance: none;
}

.custom-textarea {
  border-radius: 0px;
  border: none;
  background-color: rgb(147, 147, 147, 0.58);
  color: white;
  height: 100px;
}

.customerBoxes {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  padding: 0.5rem;
}

.customerDashContent {
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .customerBoxes {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 0.5rem;
  }
  .customerDashContent {
    flex-direction: column;
  }
}

@media only screen and (min-width: 3840px) {
  .customerBoxes {
    display: flex;
    flex-direction: column;
    width: 33%;
    height: 100px;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 0.5rem;
  }
}
