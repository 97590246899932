// todo: disabled button styles
button {
  height: 100%;
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

.c-main {
  background-color: #00194a;
  color: white;
  display: flex;
  align-items: center;
}

.c-sidebar {
  background: linear-gradient(0.25turn, #238ad6, #399ce8) !important;
}

.table-responsive {
  height: 100%;
}

.pagination {
  align-self: flex-end !important;
}

.table-responsive {
  overflow-y: hidden;
}

@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 115px;
  }
}
